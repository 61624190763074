/*
---
name: .drop-down
category: Components/DropDown
tag:
 - component
 - dropdown
compile: true
---

This dropdown has the simple mechanism to open and close a dropdown content.

closed
```jade
div.drop-down
	div.toggle open/close
	div.content My dropdown content
```

open button left
```jade
div.drop-down.-open.-bottom.-left
	div.toggle.aigis-debug open/close
	div.content My dropdown content
```

open button right
```jade
div.drop-down.-open.-bottom.-right
	div.toggle.aigis-debug open/close
	div.content My dropdown content
```

open top right
```jade
div.drop-down.-open.-top.-right
	div.toggle.aigis-debug open/close
	div.content My dropdown content
```

open top left
```jade
div.drop-down.-open.-top.-left
	div.toggle.aigis-debug open/close
	div.content My dropdown content
```

open top left aside
```jade
div.drop-down.-open.-top.-left.-aside(style="left:200px;width:50%")
	div.toggle.aigis-debug open/close
	div.content My dropdown content
```

open top right aside
```jade
div.drop-down.-open.-top.-right.-aside(style="width:50%")
	div.toggle.aigis-debug open/close
	div.content My dropdown content
```

open content relative
```jade
div.drop-down.-open.-relative(style="width:50%")
	div.toggle.aigis-debug open/close
	div.content My dropdown content
```
*/
.drop-down {

	position: relative;

	&.-open > .content {
		display: block;
	}

	&.-top > .content {
		bottom: 100%;
	}

	&.-bottom > .content {
		top: 100%;
	}

	&.-left > .content {
		left: 0;
	}

	&.-top.-left.-aside > .content {
		transform: translate( -100%, 100% );
	}

	&.-right > .content {
		right: 0;
	}

	&.-top.-right.-aside > .content {
		transform: translate( 100%, 100% );
	}

	&.-relative{
		> .content {
			position: relative;
		}
	}

	> .content {
		position: absolute;
		z-index: 1000;
		display: none;

	}

	> .toggle {
		cursor: pointer;
	}
}