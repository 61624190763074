.video-page {
    .content {
        height: 100vh; 
        display: flex; 
        flex-flow: column;
    }

    .video-container {
        margin:0 auto 15px auto; 
        position: relative;
        flex: 1;
        background-color:green;

        >.video {
             transform: translate(-50%);
             position: absolute;
             top: 0; 
             left: 0; 
             height: 100%;
        }
    }
}