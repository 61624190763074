.footer-component {
    
    color:#fff;
    background-color: #000;
    padding: rem(20px);

    &.-video {
        color:#000;
        background-color: #fff;
            .roadcross-typo {
                &.-h2 {
                    a {
                        color: #000;
                    }
                }
                &.-p {
                    color: #000;
                    a {
                        color: #000;
                        &:hover {
                            color: brand-color;
                        }
                    }
                }
            }
    }
    .disclaimer {
        text-align: right;
    }

    .roadcross-typo {
        &.-h2 {
            line-height: rem(40px);
            a {
                color: #fff;
                cursor: pointer;
                &:hover {
                    color: brand-color;
                }
            }

        }
        &.-p {
            color: lightgrey-color;
            line-height: rem(24px);
            a {
                color: lightgrey-color;

                &:hover {
                    color: brand-color;
                }
            }
        }
    }
}



@media all and ( max-width: breakpoints.small ){
    .footer-component {
        .disclaimer {
            text-align: left;
        }

        &.-video {
            .disclaimer, .contact {
                text-align: center;
            }
        }

    }
}