/*
---
name: .boolean-input
category: Inputs/Boolean
tag:
 - component
 - input
 - boolean
compile: true
---

A generic styling for a boolean-input

```jade
div.boolean-input
	div.state
		div.handle.rel
```

A generic styling for an enabled boolean-input

```jade
div.boolean-input.-enabled
	div.state
		div.handle.rel
```
*/
.boolean-input {

	margin-top: 12px;
	cursor: pointer;	

	&.-enabled {
		> .state {
			background-color: enabled-color;
			
			&:hover {
				background-color: darken(enabled-color, 10%);
			}

			> .handle {
				left: 0.1rem;
				transform:translateX(1.75rem);
			}
		}
	}

	> .state {
		background-color: disabled-color;
		transition: background-color 0.1s linear;
		width: 3.5rem;
		height: 1.5rem;
		border-radius: border-radius;
		z-index: 1;

		&:hover {
			background-color: darken(disabled-color, 7%);
			transition: background-color 0.2s linear;
		}

		> .handle {
			background-color: body-color;
			border-radius: border-radius;
			width: 1.5rem;
			height: 1.3rem;
			top: 0.1rem;
			left:0.1rem;
			z-index: 2;

			transform: translateX(0.04rem);
			transition: transform 0.2s;
		}
	}


}