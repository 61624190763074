/*
---
name: .padding-layout
category: Layout/Padding
tag:
 - component
 - layout
compile: true
---

A simple component to add paddings

## Applying paddings

The easiest use case is to use simple `.padding-layout .-all` for a padding on top, right, bottom and left with default size

```jade
div.aigis-debug.padding-layout.-all Some content
```

## Variants

The variants for the paddings are `.-top`, `.-right`, `.-bottom` and `.left` together with .-small, .-medium or .-large

```jade
div.aigis-debug.padding-layout.-small.-left Some content
```
```jade
div.aigis-debug.padding-layout.-small.-right(style="text-align:right") Some content
```
```jade
div.aigis-debug.padding-layout.-small.-top Some content
```
```jade
div.aigis-debug.padding-layout.-small.-bottom Some content
```

There are also shortcuts for vertical and horizontal

```jade
div.aigis-debug.padding-layout.-medium.-v Some content
```
```jade
div.aigis-debug.padding-layout.-medium.-h Some content
```
*/

// mixin

variants( size ){

    &.-all{
        padding: size;
    }

    for variant in top right bottom left{
        &.-{variant}{
            padding-{variant}: size;    
        }
    }

    &.-v{
        padding-top: size;
        padding-bottom: size;
    }
    &.-h{
        padding-right: size;
        padding-left: size;
    }

}

.padding-layout{

    /**
     * Generate all variants for default size
     */

    variants( paddings.default );

    /**
     *
     */
    for name, size in paddings {
        
        if name != 'default'{

            &.-{name}{
                
                variants( size )
                
            }
        }  
    }
}