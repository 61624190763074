.page-content {
    margin-top: rem(40px);

    .icon-success {
        margin-bottom: rem(30px);
    }
    .icon-lock {
        margin-top: rem(27px);
    }

    .instagram {
        margin-top: 40px;
        .icon-instagram {
            margin: 0 auto;
            display: block;
            text-align:center;
            width: 30px;
            height: 30px;
        }
        .text {
            margin-top: 15px;
            a {
                color: brand-color;
            }
        }
    
    }

}