/*
---
name: .option-input
category: Inputs/Option
tag:
 - component
 - input
 - option
compile: true
---

A generic styling for an option (Checkbox or Radio-Button)

```jade
div.form-input
	div.option-input
		<input type="checkbox" id="1" name="radio" class="element">
		<label for="1" class="label">Label Checkbox 1</label>
	div.option-input 
		<input type="radio" id="2" name="radio" class="element">
		<label for="2" class="label">Label Radio 1</label>
```
*/
.option-input {
	margin-top: 5px;

	> .element {
		cursor: pointer;
		vertical-align: middle;
		width: rem( 16px );
	}

	> .label {
		font-size: rem( 16px );
		cursor: pointer;
		vertical-align: middle;
		padding-left: 8px;
		position: relative;
	}
}