.form-component {
    
    >.title {
        margin-bottom: rem(30px);        
    }
    >.form-input > .text-input, >.form-input > .primary-button {
        width: 100%;
        margin-bottom: rem(17px);
    }
    

    .primary-button {
        background-color: brand-color;
    }

    .input {
        margin:0;
        padding:0;
        float: left;
        margin-top: 4px;
    }
    .text {
        margin-left: 25px;
    }
}