.form-input {

    >.label {
        display:block;
        margin-bottom: rem(12px);
    }

    >.error-message {
        display:none;
    }


    >.text-input {
        border-radius: 0px;
        
        &.-icon {
            padding-left: 35px;
            height: 35px;
            border: 2px solid grey-color;
        }

        &.-noborder {
            padding: rem(5px) 0;
            border: 0 ;
            border-bottom: 1px solid black;
        } 
    }

    >.option-input {
        //visibility: hidden;
        display:none;
        +label {
            cursor: pointer;
        }
        + label:before {
            border: 1px solid #000;
            content: "\00a0";
            display: inline-block;
            font: 16px/1em sans-serif;
            height: 18px;
            margin: 0 .5em 0 0;
            padding: 0;
            //vertical-align: top;
            width: 18px;
          }

          &:checked + label:before {
            border: none;
            background: brand-color;
            color: #fff;
            content: "\2713";
            text-align: center;
            padding-top: 2px;
          }

    }
    


    &.-error {
        >.error-message {
            display: block;
            color: brand-color;
        }
        >.text-input, >.option-input {
            border-color: brand-color;
        }
        .icon-key {
            border-right: 2px solid brand-color;    
        }
    }
}