.gallery-element {
	
	width: 100%;
	background-color: #000;

	> .next, .prev{
	}

	> .prev{
		transform: rotate( 180deg );
	}
}
