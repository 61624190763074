/*

*/
 
.chapter-cover {
	
	
	&.-fullimage{
		background-size: cover !important;
		background-position: center center;
		background-repeat: no-repeat;
		height: rem( 200px );
		overflow: hidden;
	}

	> .title{
		text-align: center;
	}

	> .subtitle{
		text-align: center;
	}

	> .downarrow {
		cursor: pointer;
		width: 100px;
	}
}
