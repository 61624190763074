.paging-navigation{

    height: rem( 50px );
    bottom: rem( 50px );
    cursor: pointer;

    > .rollover{
        background-color: transparent;
    }

}