/*
---
name: .fixed-container
category: Container
tag:
 - container
---

Fixed container
You can position the container with the different variants `.-top`, `.-bottom`, `.-left`, `.-right` and
set the height or width with `.-fullwidth` or `.-fullheight`

Fixed -top -left -fullwidth

```jade
div.fixed-container.-top.-left.-fullwidth.aigis-debug top left fullwidth
    
```

Fixed -top -right -fullheight

```jade
div.fixed-container.-top.-right.-fullheight.aigis-debug top right fullheight
    
```

*/

.fixed-container {
	
    position:fixed;

    &.-top{
        top: 0;
    }

    &.-bottom{
        bottom: 0;
    }

    &.-left{
        left: 0;
    }

    &.-right{
        right: 0;
    }

    &.-fullwidth{
        width: 100vw;
    }

    &.-fullheight{
        height: 100vh;
    }


}