.navigation-chapter{

    background-color:#fff;
    color:#000;

    > .title{
        display: inline-block;
    }

    > .toggle{
        display: inline-block;
        position: absolute;
        right: 0;
        top: 0;
        transform: rotate( 180deg );
    }

    &.-current{
        background-color: action-color;
        color:#fff;
    }

    &.-open{

        > .toggle{
            transform: rotate( 0 );
        }

    }

}