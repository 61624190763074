.transform-rotation{
    
    transform: rotate( 180deg );
    
    &.-90{
        transform: rotate( 90deg );
    }

    &.-270{
        transform: rotate( 270deg );
    }
    
    
}