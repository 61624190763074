/*

*/

.outline-chapter{

    margin: rem( 5px ) 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    > .element{

        width: 100%;
        min-height: rem( 5px );
        background-color: outline-navigation-color;
        transition: background-color 0.5s;
        
        &:first-child{
            border-top-left-radius: rem( 10px );
            border-top-right-radius: rem( 10px );
        }

        &:last-child{
            border-bottom-left-radius: rem( 10px );
            border-bottom-right-radius: rem( 10px );
        }
        
        &.-active{
            background-color: #000;
        }
    }
}