/*
---
name: .icon-action
category: Icons
tag:
 - component
 - icon
compile: true
---

A styling for an icon that has an action

```jade
.icon-erase.-action icon
```

A styling for an icon that floats on his parent depending on -top, -right, -left and -bottom

```jade
div.aigis-debug(style="height:70px;position:relative")
	.icon-erase.-action.-top.-right icon
```
```jade
div.aigis-debug(style="height:70px;position:relative")
	.icon-erase.-action.-top.-left icon
```
```jade
div.aigis-debug(style="height:70px;position:relative")
	.icon-erase.-action.-bottom.-right icon
```
```jade
div.aigis-debug(style="height:70px;position:relative")
	.icon-erase.-action.-bottom.-left icon
```
*/

[class^="icon-"],[class*=" icon-"] {
	&.-action {
		color: action-color;
		padding: 6px;
		cursor: pointer;
	}

	&.-top {
		position: absolute;
		z-index: 1;
		top: 2px;
	}

	&.-left {
		position: absolute;
		z-index: 1;
		left: 2px;
	}

	&.-right {
		position: absolute;
		z-index: 1;
		right: 2px;
	}

	&.-bottom {
		position: absolute;
		z-index: 1;
		bottom: -2px;
	}
}