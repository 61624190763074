.header-component {

    margin-top: rem(15px);
    text-align:center;

    .logo {
        display: block;
        margin: 0 auto;
       // margin-top: rem(23px);
        width: 200px;
    }
}