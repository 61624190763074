.privacy-policy {

    margin-bottom: rem(32px);
    .title {
        margin-bottom: rem(16px);
        text-align: center;
    }
    .text {

    }

    a, a:visited {
        color: brand-color;
    }
    a:hover {
        text-decoration: underline;
    }
}