/*
---
name: .grid-layout
category: Layout/Grid
tag:
 - component
 - layout
 - responsive
compile: true
---

A simple grid system that wraps children nodes based on the viewport width.

## Simple side by side rendering

The easiest use case is to use simple `.col -w1`, `.col -w1-2`, `.col -w1-3`, `.col -w1-4` for a side-by-side rendering.
The default value for all child elements of a `.grid-layout` is equivalent to `.col -w1`, so for only one child it will span the full width.

```jade
div.grid-layout
	div.col.-w1-2 
		div.aigis-debug col -w1-2
	div.col.-w1-2
		div.aigis-debug col -w1-2
```
```jade
div.grid-layout
	div.col.-w1-4 
		div.aigis-debug col -w1-4
	div.col.-w3-4
		div.aigis-debug col -w3-4
```

## Layouts on different devices

The breakpoints for different screen sizes are specified in the `@grid-break-points`, `@grid-break-labels` variables.
The default values define two breakpoints with labels: `.-medium-X-X` and `.-large-X-X`.
So an example grid with two breakpoints looks like this:

```jade
div.grid-layout
	div.col.-medium-1-2.-large-1-4
		div.aigis-debug col -medium-1-2 -large-1-4
	div.col.-medium-1-2.-large-1-4
		div.aigis-debug col -medium-1-2 -large-1-4
	div.col.-w1-2.-medium-1-2.-large-1-4
		div.aigis-debug col -w1-2 -medium-1-2 -large-1-4
	div.col.-w1-2.-medium-1-2.-large-1-4
		div.aigis-debug col -w1-2 -medium-1-2 -large-1-4
```

**Note:** The `col -w1` classes are not present on the first two nodes, since this is the default behaviour.


## Reverse order

To reverse the order in which the columns are displayed, you can add the modifier class `.-reverse` to your table-grid.
The columns will be in DOM order when viewport switches to small layout.

```jade
div.grid-layout.-reverse
	div.col.-w1-4
		div.aigis-debug First column in DOM
	div.col.-w1-4
		div.aigis-debug Second column in DOM
	div.col.-w1-4
		div.aigis-debug Third column in DOM
	div.col.-w1-4
		div.aigis-debug Fourth column in DOM
```
## No gutter

If you dont need a spacing/gutter between the grid columns you can use the modifier `.-nogutter`.

```jade
div.grid-layout.-nogutter
	div.col.-w1-4
		div.aigis-debug First column in DOM
	div.col.-w1-4
		div.aigis-debug Second column in DOM
	div.col.-w1-4
		div.aigis-debug Third column in DOM
	div.col.-w1-4
		div.aigis-debug Fourth column in DOM
```
*/

.grid-layout {

	margin: 0 -( grid-gutter / 2) ;

	&:after {
		content: "";
		display: table;
		clear: both;
	}

	> .col {
		float: left;
		position: relative;
		width:100%;
		padding: 0 ( grid-gutter / 2 );

		for name, perc in grid-sizes {
			&.-w{name} {
				width: perc;
			}
		}

		/**
		* Generate all media breakpoints based on the breakpoints variable.
		*/
		for breakname, breakwidth in breakpoints {

			@media all and ( min-width: breakwidth ) {

				for sizename, sizewidth in grid-sizes {
					&.-{breakname}-{sizename} {
						width: sizewidth;
					}
				}
			}
		}

	}

	&.-reverse > * {
		float:right;
	}


	&.-nogutter {
		margin: 0;

		> * {
			padding: 0;
		}
	}

}