/*
---
name: .content-tooltip
category: Tooltip
tag:
 - component
 - tooltip
compile: true
---

A tooltip to show infos on hover

```jade
div.content-tooltip.aigis-debug(style="margin-top:50px;width:400px") main content
    div.info.-above tooltip content
```
```jade
div.content-tooltip.aigis-debug(style="margin-left:150px;width:200px") main content
    div.info.-left tooltip content
```
```jade
div.content-tooltip.aigis-debug(style="margin-bottom:50px;width:400px") main content
    div.info.-below tooltip content
```
```jade
div.content-tooltip.aigis-debug(style="width:200px") main content
    div.info.-right tooltip content
```

*/

.content-tooltip{

    position: relative;

    > .info{

        position:absolute;
        display: none;
        background-color: #fff;
        padding: 10px;
        border-radius: @border-radius;

        &.-left{
            left: 0;
            top: 0;
            transform: translate( -110%, 0 );
        }

        &.-above{
            top: 0;
            left: 50%;
            transform: translate( -50%, -110% );
        }

        &.-below{
            bottom: 0;
            left: 50%;
            transform: translate( -50%, 110% );
        }

        &.-right{
            right: 0;
            top: 0;
            transform: translate( 110%, 0 );

        }

    }

    &:hover{

        > .info{
            display: block;
        }
    }
}