/*
---
name: .row-layout
category: Layout/Row
tag:
 - component
 - layout
compile: true
---

This styling allow to place containers in a row. They will **not** break as the `.grid` but resize them accordingly.
Some benefits of the `.row-layout` approach is the easy vertical centering and the usage of the `.col -auto` class.

## Basic row with vertical centering

You can use the sizing classes `.col -wX-X` to define the width of a column as the following example shows. 

```jade
div.row-layout
	div.col.-w1-4.aigis-debug col -w1-4 with a lot of text that spans the column.
	div.col.-w3-4.aigis-debug col -w3-4 content.
```

## Fixed row

There is a `.-fixed` modifier where each column has the same width.

```jade
div.row-layout.-fixed
	div.col.aigis-debug evenly spreadout column
	div.col.aigis-debug evenly spreadout column with more content
	div.col.aigis-debug evenly spreadout column
	div.col.aigis-debug evenly spreadout column with much more content than the other
	div.col.aigis-debug.vcenter evenly spreadout column with .vcenter
```


## Auto sized column width and vcenter

There is a special `.col-auto` class that resizes the column to the size of its content.
This is usefull for example if you have a fixed size logo and the rest of the header has to resize.

```jade
div.row-layout.-center
	div.col.-auto.aigis-debug
		img(src="http://placehold.it/100x100")
	div.col.-w1.aigis-debug The rest
	div.col.-auto.aigis-debug
		img(src="http://placehold.it/200x100")
```
*/

.row-layout {
	display: table;
	width: 100%;

	&.-fixed {
    	table-layout: fixed;
	}

	&.-center {
		> * {
			vertical-align: middle;
		}
	}

	&.-bottom {
		> * {
			vertical-align: bottom;
		}
	}

	&.-padded {
		> * + * {
			// padding-left: @row-gutter;
			border-left: row-gutter solid transparent;
		}
	}
	
	> .col {
		display: table-cell;
		
		for name, perc in grid-sizes {
			&.-w{name} {
				width: perc;
			}
		}
		
		&.-auto {
			width:auto;
			white-space: nowrap;
		}
	}
}

