/*
---
name: .chapter-element
category: LernBuch/Chapter
tag:
 - lernbuch
 - chapter
compile: true
---

## A chapter element for the lernbuch

`.chapter-element` centers the element in the page according to `readable-text-width` of the element

```jade
div.aigis-debug.chapter-element Some content
```

## Variants
`.-full` removes margin and spans the element to full size of the browser viewport

```jade
div.aigis-debug.chapter-element.-full Some content
```

`.-left` floates the element to the left and adds some margin on the right

```jade
div.aigis-debug.chapter-element.-left Some content
```

`.-right` floates the element to the right and adds some margin on the left

```jade
div.aigis-debug.chapter-element.-right Some content
```

*/
.chapter-element {
	margin-right: auto;
	margin-left: auto;
	max-width: readable-text-width;


	&.-full {
		max-width: none;
		margin-right: 0;
		margin-left: 0;
	}


	&.-left {
		float: left;
		margin-right: margins.small;
		margin-left: margins.large;
	}

	&.-right {
		float: right;
		margin-left: margins.small;
		margin-right: margins.large;
	}

}