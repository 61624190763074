/*
---
name: .margin-layout
category: Layout/Margin
tag:
 - component
 - layout
compile: true
---

A simple component to add margins

## Applying margins

The easiest use case is to use simple `.margin-layout .-all` for a margin on top, right, bottom and left with default margin

```jade
div.margin-layout.-all
	div.aigis-debug
```

## Variants

The variants for the margins are `.-top`, `.-right`, `.-bottom` and `.left` together with .-small, .-medium or .-large

```jade
div.margin-layout.-small.-left
    div.aigis-debug
```
```jade
div.margin-layout.-small.-right
    div.aigis-debug
```
```jade
div.margin-layout.-small.-top
    div.aigis-debug
```
```jade
div.margin-layout.-small.-bottom
    div.aigis-debug
```

There are also shortcuts for vertical and horizontal

```jade
div.margin-layout.-medium.-v
    div.aigis-debug
```
```jade
div.margin-layout.-medium.-h
    div.aigis-debug
```

There are also a variants for auto margins
```jade
div.margin-layout.-auto.-h(style="width: 200px")
    div.aigis-debug
```

*/

// mixin

variants( size ){

    &.-all{
        margin: size;
    }

    for variant in top right bottom left{
        &.-{variant}{
            margin-{variant}: size;    
        }
    }

    &.-v{
        margin-top: size;
        margin-bottom: size;
    }
    &.-h{
        margin-right: size;
        margin-left: size;
    }

}

.margin-layout{

    /**
     * Generate all variants for default size
     */

    variants( margins.default );

    /**
     *
     */
    for name, size in margins {
        
        if name != 'default'{

            &.-{name}{
                
                variants( size )
                
            }
        }  
    }
    
}