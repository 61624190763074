/*
---
name: .file-input
category: Inputs/File
tag:
 - input
 - components
compile: true
---

The image input styling to select an image.

## Default file input
```jade
.file-input.form-input
	.label Label
	.hint hint
	.row
		.col.-w1.form-status
			.input.icon-float.-top.-right
				.title My File
				.icon-erase.-action.icon
		.col.-auto
			button.primary-button Browse

```

## Image input
```jade
.file-input.-image.form-input
	.label Label
	.hint hint
	.row
		.col.-w1.form-status
			.input.icon-float.-top.-right
				img(src="http://placehold.it/350x150").responsive
				.icon-erase.-action.icon
		.col.-auto
			button.primary-button Browse
```
*/
.file-input {

	.col .-auto {
		vertical-align: bottom;
		padding-left: 10px;
	}

	&.-image {
		.form-status {
			text-align: center;
		}
	}
}