/*
---
name: .progress-bar
category: Bars/Progress
tag:
 - component
 - bar
compile: true
---

A progress-bar

```jade
div.progress-bar
    div.line.-duration
    div.line.-buffer(style="width:75%")
    div.line.-progress(style="width:50%")
```

Marks can be placed directly in the progress bar or as child of a line

```jade
div.progress-bar(style="margin-top:30px;padding-bottom:30px")
    div.line.-duration
    div.line.-buffer.-zindex1(style="width:75%")
    div.line.-progress.-zindex2(style="width:50%")
    div.mark(style="left:25%") mark behind lines
    div.line.-zindex3
        div.mark(style="left:50%") mark2 centered
        div.mark.-above(style="left:75%") mark3 above
        div.mark.-below(style="left:90%") mark4 below
```

*/

.progress-bar{

	position: relative;

	> .line {
		
		width: 100%;
		position: absolute;
		height: 3px;
		
		&.-duration{
			background-color: lighten( action-color, 30% );
		}

		&.-buffer{
			width: 0;
			background-color: lighten( action-color, 20% );
		}

		&.-progress{
			background-color: action-color;
		}


		&.-zindex1{
			z-index: 1;
		}

		&.-zindex2{
			z-index: 2;
		}

		&.-zindex3{
			z-index: 3;
		}

		&.-zindex4{
			z-index: 4;
		}
	}

	> .mark, > .line > .mark {
		display: inline-block;
    	position: absolute;
		transform: translate( -50%, -50% );

		&.-above{
			transform: translate( -50%, -100% );
		}

		&.-below{
			transform: translate( -50%, 0 );
		}
	}
}