.listoffigures-element{

    li{
        padding: 5px 0;
    }

    .link{
        cursor: pointer;
        color: action-color;
    }
}