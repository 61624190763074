/*
---
name: .primary-button
category: Buttons/Primary
tag:
 - component
 - button
compile: true
---

A button for a primary action.

```jade
button.primary-button Action
```
*/

.primary-button {

	@extend .default-button;
	border-radius: border-radius;
	font-size: rem( 16px );
	font-weight: lighter;
	white-space: nowrap;
	background-color: action-color;
	color: text-inverted-color;
	padding: 5px 10px;
}