.icon {
	fill: currentColor;
    width: 100px;
    height: 100px;

    &.icon-key {
        width: 30px;
        height: 31px;
        position: relative;
        top: 40px;
        left: 2px;
        margin-top: -40px;
        background-color:  lightgrey-color;
        padding: 5px;
        border-right: 2px solid grey-color;
    }


    &.icon-success {
        width: 111px;
        height: 85px;
    }

}
