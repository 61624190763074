.viewport-component {

    height: 100%;
        .body {
            height:100%;
            display: flex;
            flex-direction: column;
        }

    .content {
        flex: 1 0 auto;
    }

}


