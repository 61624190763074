/*
---
name: .text-input
category: Inputs/Text
tag:
 - component
 - input
 - text
compile: true
---

A generic styling for a text-input

```jade
textarea.text-input
```
*/
.text-input {

	font-size: rem( 12px );
	color: text-color;

}