.page-fluid {
    max-width: rem(375px);

    &.-privacy{
        max-width: none;
        margin: auto rem(64px);
    }

    @media all and ( max-width: breakpoints.small ){
        &.-privacy{
            margin: auto rem(8px);
        }
    }

}

@media all and ( max-width: breakpoints.xsmall ){
    .page-fluid {
        max-width: rem(250px);
    }
}