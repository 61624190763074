/*
---
name: .slider-button
category: Bars/Slider/Button
tag:
 - component
 - bar
compile: true
---

A button for a progress bar

```jade
div.progress-bar
    div.line.-duration
    div.line.-progress(style="width:50%")
    div.mark(style="left:50%")
        div.slider-button
```
*/

.slider-button{
		
	width: 10px;
	height: 10px;
	background-color: action-color;
	border-radius: 100%;
	transform: translateY( 1px );

}