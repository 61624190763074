/*
---
name: .upload-button
category: Buttons/Upload
tag:
 - component
 - upload
 - form
compile: true
---

A upload button with all the hidden form elements

```jade
div.upload-button
	input(type="file")
	div.button.primary-button
		div.label Upload
		div.progress
```
*/
.upload-button {

	> input {
		display: none;
	}

	> .button {

		padding: rem( 7px ) rem( 12px );

		> .progress {
			height: 2px;
			background-color: text-inverted-color;
		}
	}

}