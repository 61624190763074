/*
---
name: .abs-layout
category: Layout/Absolute
tag:
 - component
 - layout
compile: true
---

A simple component to position an element absolute. Default is top, left

## Variants

The variants for the vertical positioning are `.-top`, `.-vcenter` and `.-bottom``
The variants for the horizontal positioning are `.-right`, `.-hcenter` and `.-left`

```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-top.-hcenter
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-top.-right
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-right.-vcenter
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-right.-bottom
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-bottom.-hcenter
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-bottom.-left
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-left.-vcenter
```
```jade
div.abs-layout(style="width: 100%; height: 50px")
    div.aigis-debug.item.-left.-top
```

*/

.abs-layout{
    
    position: relative;


    > .item{

        position: absolute;

        &.-top{
            top: 0;    
        }

        &.-right{
            right: 0;    
        }

        &.-bottom{
            bottom: 0;    
        }

        &.-left{
            left: 0;    
        }

        &.-vcenter{
            top: 50%;
            transform: translateY( -50% );    
        }

        &.-hcenter{
            left: 50%;
            transform: translateX( -50% );    
        }
    }
}