/*
---
name: .default-button
category: Buttons/Default
tag:
 - component
 - button
---

The default button sets some basic properties so it is working as a `button` or `a` tag.

An example for a button:
```jade
button.default-button My Button
```

An example for a link:
```jade
a.default-button My Link
```
*/
.default-button {
	appearance: none;
	background: transparent;
	border: none;
	display: inline-block;
	cursor: pointer;
	outline: none;
}