.roadcross-typo {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;

    &.-a {
        color: #000;
    }
    
    &.-active {
        color: brand-color;
    }

    &.-h2 {
        font-size: rem(18px);
        letter-spacing: rem(.4px);
        line-height: rem(26px);
        font-weight: 600;
    }
    &.-bold {
        font-weight: 700;
    }

    &.-p {
        font-size: rem(14px);
        letter-spacing: rem(.31px);
        line-height: rem(20px);
        font-weight: 400;
    }

    &.-lead {
        font-size: rem(16px);
        letter-spacing: rem(.36px);
        line-height: rem(22px);
        font-weight: 600;
    }
    &.-button {
        font-size: rem(16px);
        letter-spacing: rem(.5px);
        line-height: rem(44px);
        font-weight: 700;
    }
    &.-action a{
        color: black;
        text-decoration: underline;
        line-height: rem(28px);
    }
    &.-action:hover a{
        color: grey-color;
    }
}