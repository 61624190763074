/*
---
name: .content-rollover
category: Rollover
tag:
 - rollover
---

Rollover component - content rollover rolls in - default from bottom

Trigger with mouserover by adding `.-hover` variant

Trigger with js by adding `.-show` variant

```jade
div.content-rollover.-hover
    div.main Some main content
    div.rollover Rollover content
```

Rollover component variant -left
```jade
div.content-rollover.-left.-hover
    div.main Some main content
    div.rollover Rollover content
```

Rollover component variant -right
```jade
div.content-rollover.-right.-hover
    div.main Some main content
    div.rollover Rollover content
```

Rollover component variant -top
```jade
div.content-rollover.-top.-hover
    div.main Some main content
    div.rollover Rollover content
```

Rollover component variant -top with js trigger
```jade
div.content-rollover.-top.-show
    div.main Some main content
    div.rollover Rollover content
```
*/

.content-rollover {
	
    position: relative;
    overflow: hidden;
      
    > .main {
        
    }

    > .rollover{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: translate( 0, 100% );
        background-color: rgba( 0,0,0,0.5);
        transition: transform .5s ease;

    }

    &.-left > .rollover{
        transform: translate( -100%, 0 );
    }

    &.-right > .rollover{
        transform: translateX( 100% );

    }

    &.-top > .rollover{
        transform: translateY( -100% );
    }

    &.-show > .rollover{
        transform: translate( 0, 0 );
    }
    
    &.-hover:hover > .rollover {
        transform: translate( 0, 0 );
    }

}

