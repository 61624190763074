/*
---
name: .toggle-button
category: Buttons/Toggle
tag:
 - component
 - button
compile: true
---

A button for toggling an action.
First child of the button is the first action, second child of the button is the second action

```jade
button.toggle-button
    div first-action
    div second-action
```

```jade
button.toggle-button.-toggled
    div first-action
    div second-action
```
*/
.toggle-button {

	@extend .primary-button;

    > *:last-child{
        display: none;
    }
	
    &.-toggled{

        > *:first-child{
            display: none;
        }

        > *:last-child{
            display: block
        }
    }
}